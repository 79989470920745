import type { LDFlagSet, LDFlagValue } from 'launchdarkly-react-client-sdk'
import { useFlags } from 'launchdarkly-react-client-sdk'

export function useFeatureFlag(
  flagName: string,
  defaultValue = false,
): LDFlagValue {
  const flags = useFlags()

  return flags[flagName] ?? defaultValue
}

export function useAllFeatureFlags(): LDFlagSet {
  const flags = useFlags()

  return flags
}
