import './config/I18n'
import './style/app.scss'

import { setConfig } from '@promoboxx/social-preview'
import { setFilterStore } from '@promoboxx/use-filter/dist/store'
import Honeybadger from 'honeybadger-js'
import jsCookie from 'js-cookie'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import analytics from '@src/analytics'
import ErrorBoundary from '@src/components/shared/ErrorBoundary'
import services from '@src/config/services'
import { initHistory } from '@src/lib/history'

import App from './components/App'
import { deleteLegacyAuthHeaders } from './components/auth/omniauthHelpers'
import AppConfig, { initConfig } from './config/AppConfig'
import { injectAntiAdblock } from './lib/anti-adblock'
import urlParamStore from './lib/urlParamStore'

const AppInstance = () => {
  useEffect(() => {
    //Analytics
    analytics.track({
      event: 'Event',
      type: 'app-launched',
    })
  }, [])

  return <App />
}

async function main() {
  await initConfig().catch((err) => {
    console.error(err)
    return
  })

  initHistory()

  deleteLegacyAuthHeaders()

  injectAntiAdblock({
    jsCookie,
  })

  //Honeybadger
  const honeybadgerConfig = {
    api_key: AppConfig.HONEYBADGER_API_KEY,
    environment: AppConfig.ENV,
    revision: AppConfig.COMMITHASH,
  }
  const honeybadger = Honeybadger.configure(honeybadgerConfig)

  // social-preview
  setConfig({
    APP_BASE: AppConfig.SOCIAL_PREVIEW_APP_BASE,
  })

  setFilterStore(urlParamStore)

  await services.init() //After this point you can confirm that the window.analytics object is hydrated.

  const root = createRoot(document.getElementById('root'))

  // TODO
  // - some magic with .pug files, translating them into bog-standard JS
  // - search for things using `htmlWebpackPlugin.options.config.`, that will now be `AppConfig.`
  root.render(
    <ErrorBoundary honeyBadger={honeybadger}>
      <AppInstance />
    </ErrorBoundary>,
  )
}

main()
