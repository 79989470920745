const open = () => {
  window.$zopim?.livechat.window.show()
}
const close = () => {
  window.$zopim?.livechat.window.hide()
}
const hideAll = () => {
  window.$zopim?.livechat.hideAll()
}

export const helpWidget = {
  open,
  close,
  hideAll,
}
